<template>
  <div>
    <b-card-actions
      title="Filters"
      action-collapse
      collapsed
    >
      <b-row>
        <b-col
          cols="12"
          md="3"
        >
          <b-form-group
            label="Waybill Number"
            label-for="waybill_number"
          >
            <b-form-input
              id="waybill_number"
              v-model="filters['waybill_number']"
              placeholder="Search"
              autocomplete="nope"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="3"
        >
          <b-form-group
            label=" Customer Name"
            label-for="customer_name"
          >
            <b-form-input
              id="customer_name"
              v-model="filters['order.customer_name']"
              placeholder="Search"
              autocomplete="nope"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row class="mt-1">
        <b-col>
          <filter-buttons
            :filter-data="filters"
            :apply-filters="applyFilters"
            :clear-filters="clearFilters"
          />
        </b-col>
      </b-row>
    </b-card-actions>

    <b-card
      no-body
    >
      <div>
        <div
          class="mx-2 mt-2 mb-1"
        >
          <b-row class="d-sm-flex align-items-start">
            <b-col
              class="d-flex flex-wrap justify-content-end align-items-center mb-1 mb-md-0"
            >
              <ReportTableButtons
                :items="rows"
                :json_fieldz="json_fields"
                :name="`Feedback - Page ${page}.xls`"
                :bulkname="`Feedback.xls`"
                :fetch="getFeedbackNoPagination"
                @refresh="onClickRefresh"
              />
            </b-col>
          </b-row>
        </div>

        <!-- table -->
        <b-overlay
          id="overlay-background"
          variant="light"
          opacity="0.30"
          blur="10px"
          rounded="sm"
        >
          <b-table
            striped
            hover
            responsive
            :per-page="perPage"
            :items="rows"
            :fields="fields"
            show-empty
            sticky-header="100vh"
          >
            <template #empty>
              <TableDataFetching
                :rows="rows"
                :data-loading="dataLoading"
              />
            </template>
            <template #cell(waybill_number)="data">
              <div>
                {{ data.item.order ? data.item.order.waybill_number : '' }}
              </div>
            </template>
            <template #cell(customer_name)="data">
              <div>
                {{ data.item.order ? data.item.order.customer_name : '' }}
              </div>
            </template>
            <template #cell(description)="data">
              {{ data.value.length > 50 ?data.value.substring(0, 50)+'...' : data.value }}
            </template>
            <template #cell(rating)="data">
              <div class="d-flex justify-content-center align-items-center">
                <star-rating
                  v-model="data.value"
                  :star-size="20"
                  :read-only="true"
                  :show-rating="false"
                  :glow="2"
                />
              </div>
            </template>
            <template #cell(created_at)="data">
              <div>
                {{ __dateTimeFormatter(data.value) }}
              </div>
            </template>
            <template #cell(action)="data">
              <b-dropdown
                variant="link"
                toggle-class="text-decoration-none"
                no-caret
              >
                <template v-slot:button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="12"
                    class="text-body align-middle mr-25"
                  />
                </template>
                <b-dropdown-item
                  @click="onClickView(data.item)"
                >
                  <feather-icon icon="EyeIcon" />
                  View
                </b-dropdown-item>
              </b-dropdown>
            </template>
          </b-table>
          <div class="mx-2 mb-2">
            <b-row
              cols="12"
            >
              <b-col
                class="d-flex align-items-center justify-content-sm-start"
                md="3"
              >
                <label class="width-75">Per page</label>
                <v-select
                  v-model="perPage"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="pageOptions"
                  :clearable="false"
                  class="per-page-selector d-inline-block width-125"
                />
              </b-col>
              <!-- Pagination -->
              <b-col
                class="d-flex align-items-center justify-content-sm-end"
                md="9"
              >
                <span class="text-muted pagination-text  mt-1 mt-md-0 mr-1">Showing {{ meta.from }} to {{ meta.to }} of {{ meta.total }} entries</span>
                <b-pagination
                  v-model="page"
                  :per-page="perPage"
                  :total-rows="(meta.total)? meta.total : 0"
                  class="mb-0 mt-1 mt-sm-0"
                  first-number
                  last-number
                  next-class="next-item"
                  prev-class="prev-item"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>
              </b-col>
            </b-row>
          </div>
        </b-overlay>
      </div>
    </b-card>
    <view-notification-modal ref="view_notification_modal" />
  </div>
</template>

<script>
import {
  BCard,
  BCol,
  BPagination,
  BRow,
  BFormGroup,
  BOverlay,
  VBModal,
  BTable,
  BDropdown,
  BDropdownItem,
  BFormInput,
  VBTooltip,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import SuccessMessage from '@core/mixins/SuccessMessage'
import ErrorMessage from '@core/mixins/ErrorMessage'
import Filter from '@core/mixins/filter'
import vSelect from 'vue-select'
// import flatPickr from 'vue-flatpickr-component'
import { avatarText } from '@core/utils/filter'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import StarRating from 'vue-star-rating'
import FilterButtons from '@/components/Filter/ReportFilterButtons.vue'
import ViewNotificationModal from '@/components/Notification/ViewNotificationModal.vue'
import { RepositoryFactory } from '@/repository/RepositoryFactory'

const feedbackRepository = RepositoryFactory.get('feedback')

export default {
  directives: {
    'b-modal': VBModal,
    'b-tooltip': VBTooltip,
    Ripple,
  },
  components: {
    BPagination,
    BCard,
    BRow,
    BCol,
    BTable,
    BOverlay,
    vSelect,
    // flatPickr,
    BDropdown,
    BDropdownItem,
    BFormGroup,
    // eslint-disable-next-line vue/no-unused-components
    BFormInput,
    BCardActions,
    ViewNotificationModal,
    StarRating,
    FilterButtons,
  },
  mixins: [Filter, SuccessMessage, ErrorMessage],
  data() {
    return {
      // Filters Start
      filters: {},
      // Filters End
      avatarText,
      total: 0,
      meta: {},
      dataLoading: false,
      updateFeedbackPeriodLoading: false,
      sort: '',
      perPage: 20,
      pageOptions: [10, 20, 50, 100, 200],
      totalRows: 1,
      page: 1,
      form: {},
      merchantOptions: [],
      cleaveOptions: {
        numeral: true,
        prefix: ' days',
        tailPrefix: true,
      },
      fields: [
        {
          key: 'waybill_number',
          label: 'Waybill Number',
          sortable: true,
          thClass: 'text-left',
          tdClass: 'text-left',
        },
        {
          key: 'customer_name',
          label: 'Customer Name',
          sortable: true,
          thClass: 'text-left',
          tdClass: 'text-left',
        },
        {
          key: 'description',
          label: 'Message',
          sortable: true,
          thClass: 'text-left',
          tdClass: 'text-left',
        },
        {
          key: 'rating',
          label: 'Rating',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          key: 'created_at',
          label: 'Created At',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          key: 'action',
          label: 'Action',
          sortable: false,
        },
      ],
      rows: [],
      json_fields: {
        'Waybill Number': {
          field: 'order.waybill_number',
          callback: value => `"${value}"`,
        },
        'Customer Name': {
          field: 'order.customer_name',
          callback: value => `"${value}"`,
        },
        Message: {
          field: 'description',
          callback: value => `"${value}"`,
        },
        Rating: 'rating',
        'Created At': {
          field: 'created_at',
          // eslint-disable-next-line no-underscore-dangle
          callback: value => this.__dateTimeFormatter(value),
        },
      },
    }
  },
  watch: {
    page() {
      this.getFeedback()
    },
    perPage() {
      this.getFeedback()
    },
  },
  async mounted() {
    this.getFeedback()
    this.getFeedbackSetting()
    this.totalRows = this.rows.length
  },

  methods: {
    async getFeedback() {
      this.dataLoading = true
      this.rows = []
      try {
        // eslint-disable-next-line no-underscore-dangle
        this.filterQuery = this.__refineDateQuery(this.filterQuery, [this.filters])
        const { data } = (await feedbackRepository.getAllFeedback(this.page, this.filterQuery, this.perPage))
        this.rows = data.data
        this.meta = data.meta
      } catch (e) {
        this.convertAndNotifyError(e)
      }
      this.dataLoading = false
    },
    async getFeedbackNoPagination() {
      try {
        // eslint-disable-next-line no-underscore-dangle
        this.filterQuery = this.__refineDateQuery(this.filterQuery, [this.filters])
        const { data } = (await feedbackRepository.getFeedbackNoPagination(this.filterQuery))
        return data.data
      } catch (e) {
        this.convertAndNotifyError(e)
        return []
      }
    },
    onClickView(data) {
      this.$refs.view_notification_modal.openModal(data, 'View Feedback')
    },
    // REQUIRED
    handleChangePage(page) {
      this.page = page
      this.getFeedback()
    },
    changePage(value) {
      this.perPage = value
    },
    onClickRefresh() {
      this.getFeedback()
    },
    applyFilters() {
      this.getFeedback()
    },
    clearFilters() {
      this.filters = {}
      this.$nextTick(() => {
        this.getFeedback()
      })
    },
  },
}
</script>
<style lang="scss">
@import '../@core/scss/vue/libs/vue-select.scss';
@import '../@core/scss/vue/libs/vue-flatpicker.scss';
</style>
